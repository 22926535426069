(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define("popover-helper", [], factory);
	else if(typeof exports === 'object')
		exports["popover-helper"] = factory();
	else
		root["popover-helper"] = factory();
})(self, () => {
return 